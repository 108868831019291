<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      width="600px"
      title="进行中订单状态分布"
    >
      <a-spin :spinning="loading">
        <div class="custom-padding-bottom-10px">
          <div
            ref="statisticsChart"
            style="height: 200px;"
          />
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findServiceOrderStatisticUnderwayStatusCount } from '@/api/order_statistic'

export default {
  // 订单状态数量统计图
  name: 'StatusCountStatisticsChart',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      data: [],
      // 图表
      chart: null,
      // 总数
      total: 0
    }
  },
  mounted() {
    this.fetchData()
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    // 图表默认配置
    chartOption() {
      return {
        // 标题
        title: {
          text: '殡仪服务订单状态分布',
          bottom: 0,
          left: 'center'
        },
        // 提示框
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return params.marker + this.$lodash.truncate(params.data.name, { length: 9 }) +
                '&nbsp;&nbsp;' + params.value + '个'
          }
        },
        // 图例
        legend: {
          orient: 'vertical',
          top: 'middle',
          right: 0,
          type: 'scroll'
        },
        // 数据组
        series: [
          {
            avoidLabelOverlap: false,
            label: { show: false },
            labelLine: { show: true },
            type: 'pie',
            radius: '50%',
            data: []
          }
        ]
      }
    }
  },
  methods: {
    // 绘制图表
    drawChart() {
      this.chart ||= this.$echarts.init(this.$refs.statisticsChart)
      this.chartOption.series[0].data = []
      for (let i = 0; i < this.data.length; i++) {
        const statistic = this.data[i]
        this.chartOption.series[0].data[i] = {
          name: this.$lodash.truncate(statistic.name, { length: 9 }),
          value: statistic.count
        }
      }
      // 全部重新加载-解决tooltip.formatter params不更新的问题
      this.chart.setOption(this.chartOption, true)
      this.chart.on('click', 'series', function(params) {
        if (params.name === '进行中') {
        }
      })
      // 添加表格自适应
      window.addEventListener('resize', () => {
        this.chart.resize()
      })
    },

    fetchData() {
      this.loading = true
      this.total = 0
      findServiceOrderStatisticUnderwayStatusCount().then((res) => {
        if (res.code === 0) {
          this.data = res.data
          for (let i = 0; i < this.data.length; i++) {
            this.total += this.data[i].count
          }
          this.drawChart()
        }
        this.loading = false
      })
    }
  },
  watch: {
    // 监听间隔时间
    intervalTime() {
      this.fetchData()
    }
  },
  beforeDestroy() {
    // 移除chart表格自适应监听
    window.removeEventListener('resize', () => {
      this.chart.resize()
    })
  }
}
</script>

<style lang="less" scoped>
.total {
  width: 100%;
  font-size: 30px;
  position: absolute;
  top: 38%;
  text-align: center;
}
</style>
